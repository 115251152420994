





























































import { defineComponent } from '@vue/composition-api'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { mapActions, mapState } from 'vuex'
import Pagination from '@/components/base/Pagination.vue'
import {
  usePagination,
  PaginationOptions, parseQuery,
} from '@/composables/pagination'
import { QueryParams } from '@/store/api-plateform-utils'
import router from '@/router'
import { Configuration } from '@/api/interfaces/configuration'

export default defineComponent({
  setup (props, ctx) {
    const query = {
      ...router.app.$route.query,
    }
    const totalItems = () => {
      return ctx.root.$store.state.configuration.totalItems
    }
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      }: PaginationOptions = usePagination(
    totalItems,
    parseQuery(query),
  ) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
    }
  },
  name: 'ConfigurationList',
  components: {
    TopBar,
    Pagination,
  },
  async created () {
    this.load(this.baseRequest)
  },
  data () {
    return {
      title: this.$t('configuration.list.headers.title'),
      headers: [
        {
          text: this.$t('configuration.list.headers.code'),
          align: 'start',
          value: 'code',
        },
        {
          text: this.$t('configuration.list.headers.value'),
          align: 'start',
          value: 'value',
        },
        {
          text: this.$t('configuration.list.headers.actions'),
          value: 'actions',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('configuration', {
      configurationList: 'list',
      totalItems: 'totalItems',
      loading: 'loading',
    }),
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        ...this.paginationRequestParams,
      }
      return request as QueryParams
    },
  },
  watch: {
    paginationOptions: {
      handler () {
        this.loadData()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('configuration', {
      load: 'load',
    }),
    loadData () {
      this.addParamsToLocation()
      this.load(this.baseRequest)
    },
    addParamsToLocation () {
      let query: any = {
        ...this.paginationOptions,
      }
      query = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => !!value),
      )

      router.replace({ name: 'Configuration List', query: query })
    },
    goToConfigurationForm (item: Configuration) {
      if (item.id) {
        this.$router.push({
          name: 'Configuration Edit',
          params: { idConfiguration: item.id.toString() },
        })
      }
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'Configuration Edit') {
      this.$store.commit('navigation/resetPile')
      this.$store.commit('navigation/addRoute', from)
    }
    next()
  },
})
